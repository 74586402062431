import "./main.scss";
import "./main.css";
import "@radix-ui/themes/styles.css";
import contactBackImg from "../app/images/1.jpg";
import contactCircle from "../app/images/contact_circle.jpg";
import { useRef } from "react";
import p1 from "../app/images/studing/1.jpg";
import p2 from "../app/images/studing/2.jpg";
import p3 from "../app/images/studing/3.jpg";
import p4 from "../app/images/studing/4.jpg";
import p5 from "../app/images/studing/5.jpg";
import p5_new from "../app/images/studing/5_new.jpg";
import p6 from "../app/images/studing/6.jpg";
import p6_new from "../app/images/studing/6_new.jpg";
import p7 from "../app/images/studing/7.jpg";
import p8 from "../app/images/studing/8.jpg";
import p9 from "../app/images/studing/9.jpg";
import p10 from "../app/images/studing/10.jpg";
import p10_new from "../app/images/studing/10_new.jpg";
import p11 from "../app/images/studing/11.jpg";
import p11_new from "../app/images/studing/11_new.jpg";
import p12 from "../app/images/studing/12.jpg";
import course1 from "../app/images/courses/1.jpg";
import course1_new from "../app/images/courses/1_new.jpg";
import course2 from "../app/images/courses/2.jpg";
import course2_new from "../app/images/courses/2_new.jpg";
import course3 from "../app/images/courses/3.jpg";
import course3_new from "../app/images/courses/3_new.jpg";
import { Button, Dialog, Flex } from "@radix-ui/themes";

export default function Main() {
  const targetRef = useRef(null);

  const scrollToTarget = () => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onClickInst = () => {
    window.open(
      "https://ig.me/m/jaaane_brows?text=%D0%95%D0%B2%D0%B3%D0%B5%D0%BD%D0%B8%D1%8F,+%D0%B4%D0%BE%D0%B1%D1%80%D1%8B%D0%B9+%D0%B4%D0%B5%D0%BD%D1%8C.+%D0%9F%D0%B5%D1%80%D0%B5%D1%88%D0%BB%D0%B0+%D0%B2+%D0%B4%D0%B8%D0%B0%D0%BB%D0%BE%D0%B3+%D1%81+%D0%B2%D0%B0%D0%BC%D0%B8+%D1%87%D0%B5%D1%80%D0%B5%D0%B7+%D1%81%D0%B0%D0%B9%D1%82.+%D0%9F%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%D1%82%D0%B5+%D0%BF%D0%BE%D0%B6%D0%B0%D0%BB%D1%83%D0%B9%D1%81%D1%82%D0%B0,+",
      "_blank"
    );
  };

  const onClickWA = () => {
    window.open(
      "https://wa.me/+79282629007?text=%D0%95%D0%B2%D0%B3%D0%B5%D0%BD%D0%B8%D1%8F,+%D0%B4%D0%BE%D0%B1%D1%80%D1%8B%D0%B9+%D0%B4%D0%B5%D0%BD%D1%8C.+%D0%9F%D0%B5%D1%80%D0%B5%D1%88%D0%BB%D0%B0+%D0%B2+%D0%B4%D0%B8%D0%B0%D0%BB%D0%BE%D0%B3+%D1%81+%D0%B2%D0%B0%D0%BC%D0%B8+%D1%87%D0%B5%D1%80%D0%B5%D0%B7+%D1%81%D0%B0%D0%B9%D1%82.+%D0%9F%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%D1%82%D0%B5+%D0%BF%D0%BE%D0%B6%D0%B0%D0%BB%D1%83%D0%B9%D1%81%D1%82%D0%B0,+",
      "_blank"
    );
  };

  const onClickTg = () => {
    window.open(
      "https://t.me/jaaane_brows?text=%D0%95%D0%B2%D0%B3%D0%B5%D0%BD%D0%B8%D1%8F,%20%D0%B4%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C.%20%D0%9F%D0%B5%D1%80%D0%B5%D1%88%D0%BB%D0%B0%20%D0%B2%20%D0%B4%D0%B8%D0%B0%D0%BB%D0%BE%D0%B3%20%D1%81%20%D0%B2%D0%B0%D0%BC%D0%B8%20%D1%87%D0%B5%D1%80%D0%B5%D0%B7%20%D1%81%D0%B0%D0%B9%D1%82.%20%D0%9F%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D0%B6%D0%B8%D1%82%D0%B5%20%D0%BF%D0%BE%D0%B6%D0%B0%D0%BB%D1%83%D0%B9%D1%81%D1%82%D0%B0,%20",
      "_blank"
    );
  };

  return (
    <div className="main">
      <section>
        <div className="additional-info">
          <label>brow</label>
          <label>beauty</label>
          <label>master</label>
        </div>
        <div className="name">
          <label>Евгения</label>
          <label>
            Оформление бровей
            <br />
            ламинирование ресниц
          </label>
        </div>
        <div className="order-btn" onClick={scrollToTarget}>
          ЗАПИСАТЬСЯ
        </div>
      </section>
      <section>
        <div className="principes">
          <div className="principes-header">
            <h2>МОИ ПРИНЦИПЫ</h2>
            <h3>В РАБОТЕ</h3>
          </div>
          <div className="scnd-img" />
          <div className="about">
            я мастер по оформлению бровей и ламинированию ресниц, со стажем 6
            лет, из которых 4 года обучаю. <br />
            <br /> мои клиенты ходят до 2х месяцев с ярким эффектом ресниц с
            чистой коррекцией и окрашиванием бровей до 3-4 недель.
          </div>
        </div>
      </section>
      <section>
        <div className="services">
          <div className="services-header">
            <h2>УСЛУГИ</h2>
            <h3>И СТОИМОСТЬ</h3>
          </div>
          <div className="services-grid">
            <div className="cell">
              <span className="work-title">Ламинирование ресниц</span>
              <span className="work-content">
                входит ламинирование, окрашивание, уход
              </span>
              <span className="work-price">2000₽</span>
            </div>
            <div className="cell two" />
            <div className="cell seven" />
            <div className="cell">
              <span className="work-title">Коррекция с окрашиванием</span>
              <span className="work-content">
                входит коррекция пинцет/воск, окрашивание или осветление
              </span>
              <span className="work-price">1000₽</span>
            </div>
            <div className="cell">
              <span className="work-title">Коррекция</span>
              <span className="work-content">
                входит коррекция пинцет/воск, уход
              </span>
              <span className="work-price">900₽</span>
            </div>
            <div className="cell six"></div>
            <div className="cell four" />
            <div className="cell">
              <span className="work-title">Ламинирование бровей с уходом</span>
              <span className="work-price">1200₽</span>
            </div>
            <div className="cell">
              <span className="work-title">
                Ламинирование бровей с коррекцией
              </span>
              <span className="work-price">1300₽</span>
            </div>
            <div className="cell five" />
            <div className="cell last" />
            <div className="cell">
              <span className="work-title">
                Удаление пушка над верхней губой
              </span>
              <span className="work-price">300₽</span>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="sets">
          <div className="sets-header">
            <h2>Комплексы</h2>
          </div>
          <div className="sets-list">
            <div className="set">
              <span className="set-title">Ламинирование бровей 4 в 1</span>
              <span className="set-price">1500₽</span>
            </div>
            <div className="set">
              <span className="set-title">
                Ламинирование ресниц с уходом, оформление бровей, удаление пушка
                над верхней губой
              </span>
              <span className="set-price">3000₽</span>
            </div>
            <div className="set">
              <span className="set-title">
                Ламинирование ресниц, ламинирование и оформление бровей,
                удаление пушка над верхней губой
              </span>
              <span className="set-price">3300₽</span>
            </div>
          </div>
          <div className="order-btn" onClick={scrollToTarget}>
            ЗАПИСАТЬСЯ
          </div>
        </div>
      </section>
      <section>
        <div className="portfolio">
          <div className="portfolio-header">
            <h2>Портфолио</h2>
            <h3>с работами</h3>
          </div>
          <div className="portfolio-content">
            <div className="wrapper">
              <div className="container">
                <input type="radio" name="slide" id="c1" defaultChecked />
                <label htmlFor="c1" className="card">
                  <div className="row"></div>
                </label>
                <input type="radio" name="slide" id="c2" />
                <label htmlFor="c2" className="card">
                  <div className="row"></div>
                </label>
                <input type="radio" name="slide" id="c3" />
                <label htmlFor="c3" className="card">
                  <div className="row"></div>
                </label>
                <input type="radio" name="slide" id="c4" />
                <label htmlFor="c4" className="card">
                  <div className="row"></div>
                </label>
                <input type="radio" name="slide" id="c5" />
                <label htmlFor="c5" className="card">
                  <div className="row"></div>
                </label>
                <input type="radio" name="slide" id="c6" />
                <label htmlFor="c6" className="card">
                  <div className="row"></div>
                </label>
                <input type="radio" name="slide" id="c7" />
                <label htmlFor="c7" className="card">
                  <div className="row"></div>
                </label>
                <input type="radio" name="slide" id="c8" />
                <label htmlFor="c8" className="card">
                  <div className="row"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="courses">
          <div className="courses-header">
            <h2>Обучение</h2>
          </div>
          <div className="box-start">
            <div className="course start">
              <div className="course-header">
                <img src={course1_new} alt="" />
                <span>💫 Начальный</span>
              </div>
              <div className="course-body">
                <span>📎 Обучение индивидуальное. Длительность 2 дня;</span>
                <span>📎 Отработка на 2 моделях;</span>
                <span>📎 Скидка на закупку материалов;</span>
                <span>📎 Моя обратная связь 1 месяц;</span>
                <span>📎 Фуршет, вручение сертификата;</span>
              </div>
              <Dialog.Root>
                <Dialog.Trigger>
                  <span className="about-course">подробнее</span>
                </Dialog.Trigger>
                <Dialog.Content size={3} maxHeight={"35rem"}>
                  <Dialog.Title>📝 Программа</Dialog.Title>
                  <div className="course-program">
                    <div className="course-program-body">
                      <span>━ строение кожи</span>
                      <span>━ жизненный цикл волос</span>
                      <span>━ строение и пористость волоса</span>
                      <span>━ типы кожи. Подготовка кожи к окрашиванию</span>
                      <span>━ анализ клиента</span>
                      <span>━ окислитель (роль, принцип работы)</span>
                      <span>
                        ━ цветовое направление, уровень глубины тона (ЦН, УГТ)
                      </span>
                      <span>━ колористика</span>
                      <span>━ pH баланс</span>
                      <span>━ круг Итенна что это и для чего</span>
                      <span>━ строение брови</span>
                      <span>
                        ━ разметка бровей. нахождение 10 точек и построение
                        эскиза
                      </span>
                      <span>━ техники окрашивания (графика, натуральные)</span>
                      <span>━ разбор красителя shik</span>
                      <span>━ коррекция пинцетом</span>
                      <span>━ дезинфекция инструментов</span>
                      <span>━ материалы для работы</span>
                      <span>━ памятки для клиентов</span>
                      <span>━ психология с клиентом</span>
                      <span>━ приложения для обработки фото, видео</span>
                      <span>━ урок по съемке видео и обработке</span>
                      <span>━ готовый контент для соц сетей</span>
                    </div>
                    <div className="course-price">
                      <span className="price">💸 15 000 ₽</span>
                    </div>
                  </div>
                  <Flex gap="3" justify="center">
                    <Dialog.Close>
                      <Button variant="soft" color="gray">
                        Закрыть
                      </Button>
                    </Dialog.Close>
                  </Flex>
                </Dialog.Content>
              </Dialog.Root>
            </div>
          </div>
          <div className="box-standart">
            <div className="course standart">
              <div className="course-header">
                <img src={course2_new} alt="" />
                <span>⭐ Стандартный</span>
              </div>
              <div className="course-body">
                <span>📎 Обучение индивидуальное. Длительность 2 дня;</span>
                <span>📎 Отработка на 3 моделях;</span>
                <span>📎 Скидка на закупку материалов;</span>
                <span>📎 Моя обратная связь 1 месяц;</span>
                <span>📎 Фуршет, вручение сертификата;</span>
              </div>
              <Dialog.Root>
                <Dialog.Trigger>
                  <span className="about-course">подробнее</span>
                </Dialog.Trigger>
                <Dialog.Content size={3} maxHeight={"35rem"}>
                  <Dialog.Title>📝 Программа</Dialog.Title>
                  <div className="course-program">
                    <div className="course-program-body">
                      <span>━ строение кожи</span>
                      <span>━ жизненный цикл волос</span>
                      <span>━ строение и пористость волоса</span>
                      <span>━ типы кожи. Подготовка кожи к окрашиванию</span>
                      <span>━ анализ клиента</span>
                      <span>━ окислитель (роль, принцип работы)</span>
                      <span>
                        ━ цветовое направление, уровень глубины тона (ЦН, УГТ)
                      </span>
                      <span>━ колористика</span>
                      <span>━ pH баланс</span>
                      <span>━ круг Итенна что это и для чего</span>
                      <span>━ строение брови</span>
                      <span>
                        ━ разметка бровей. нахождение 10 точек и построение
                        эскиза
                      </span>
                      <span>
                        ━ техники окрашивания (графика, натуральные, волосковая)
                      </span>
                      <span>━ разбор красителя shik, loca</span>
                      <span>
                        ━ процедура ламинирование бровей/долговременная укладка
                        бровей
                      </span>
                      <span>━ плюсы и минусы процедуры</span>
                      <span>━ как не затемнить брови после ламинирования</span>
                      <span>━ коррекция пинцетом</span>
                      <span>━ дезинфекция инструментов</span>
                      <span>━ материалы для работы </span>
                      <span>━ памятки для клиентов</span>
                      <span>━ психология с клиентом</span>
                      <span>━ учимся фотографировать и снимать</span>
                      <span>━ приложения для обработки фото, видео</span>
                      <span>━ урок по съемке видео и обработке</span>
                      <span>━ готовый контент для соц сетей</span>
                    </div>
                    <div className="course-price">
                      <span className="price">💸 20 000₽</span>
                    </div>
                  </div>
                  <Flex gap="3" justify="center">
                    <Dialog.Close>
                      <Button variant="soft" color="gray">
                        Закрыть
                      </Button>
                    </Dialog.Close>
                  </Flex>
                </Dialog.Content>
              </Dialog.Root>
            </div>
          </div>
          <div className="box-pro">
            <div className="course pro">
              <div className="course-header">
                <img src={course3_new} alt="" />
                <span>🔥 Продвинутый</span>
              </div>
              <div className="course-body">
                <span>📎 Обучение индивидуальное. Длительность 4 дня;</span>
                <span>📎 Отработка на 5 моделях;</span>
                <span>📎 Скидка на закупку материалов;</span>
                <span>📎 Моя обратная связь навсегда;</span>
                <span>
                  📎 Предоставление места, после обучения в студии бесплатно (с
                  выходным 3 раза);
                </span>
                <span>📎 Фуршет, вручение сертификата;</span>
              </div>
              <Dialog.Root>
                <Dialog.Trigger>
                  <span className="about-course">подробнее</span>
                </Dialog.Trigger>
                <Dialog.Content size={3} maxHeight={"35rem"}>
                  <Dialog.Title>📝 Программа</Dialog.Title>
                  <div className="course-program">
                    <div className="course-program-body">
                      <span>━ строение кожи</span>
                      <span>━ жизненный цикл волос</span>
                      <span>━ строение и пористость волоса</span>
                      <span>━ типы кожи, подготовка кожи к окрашиванию</span>
                      <span>━ анализ клиента</span>
                      <span>━ окислитель (роль, принцип работы)</span>
                      <span>
                        ━ цветовое направление, уровень глубины тона (ЦН, УГТ)
                      </span>
                      <span>━ колористика</span>
                      <span>━ химия красителей</span>
                      <span>━ pH баланс</span>
                      <span>━ круг Итенна что это и для чего</span>
                      <span>━ строение брови</span>
                      <span>
                        ━ разметка бровей. нахождение 10 точек и построение
                        эскиза
                      </span>
                      <span>
                        ━ техники окрашивания (графика, натуральные, волосковая)
                      </span>
                      <span>
                        ━ разбор красок bronsun, thuya, igora, estel, shik, loca
                      </span>
                      <span>━ уходовая процедура для бровей</span>
                      <span>
                        ━ процедура ламинирование бровей/долговременная укладка
                        бровей
                      </span>
                      <span>━ показания и противопоказания к процедуре</span>
                      <span>━ плюсы и минусы процедуры</span>
                      <span>━ ламинирование без «зализанных бровей»</span>
                      <span>━ как не затемнить брови после ламинирования</span>
                      <span>━ коррекция пинцетом/работа с воском</span>
                      <span>
                        ━ удаление пушка над верхней губой (доп. процедура)
                      </span>
                      <span>━ дезинфекция инструментов</span>
                      <span>━ материалы для работы</span>
                      <span>━ памятки для клиентов</span>
                      <span>━ психология с клиентом</span>
                      <span>
                        ━ учимся фотографировать и снимать, идеи для фото/видео
                      </span>
                      <span>━ как найти клиентов и удержать их</span>
                      <span>━ приложения для обработки фото, видео</span>
                      <span>━ урок по съемке видео и обработке</span>
                      <span>━ готовый контент для соц сетей</span>
                    </div>
                    <div className="course-price">
                      <span className="price">💸 25 000₽</span>
                    </div>
                  </div>
                  <Flex gap="3" justify="center">
                    <Dialog.Close>
                      <Button variant="soft" color="gray">
                        Закрыть
                      </Button>
                    </Dialog.Close>
                  </Flex>
                </Dialog.Content>
              </Dialog.Root>
            </div>
          </div>
          <div className="box-lami">
            <div className="course pro">
              <div className="course-header">
                <img src={p5} alt="" style={{ objectPosition: "0 -100px" }} />
                <span>
                  🔥 Базовый курс{" "}
                  <span
                    style={{
                      fontFamily: "Roboto",
                      padding: 0,
                      fontWeight: 800,
                    }}
                  >
                    Lami master
                  </span>
                </span>
              </div>
              <div className="course-body">
                <span>📎 Обучение индивидуальное. Длительность 4 дня;</span>
                <span>📎 2 дня, отработка на 3 моделях;</span>
              </div>
              <Dialog.Root>
                <Dialog.Trigger>
                  <span className="about-course">подробнее</span>
                </Dialog.Trigger>
                <Dialog.Content size={3} maxHeight={"35rem"}>
                  <Dialog.Title>📝 Программа</Dialog.Title>
                  <div className="course-program">
                    <div
                      className="course-program-body"
                      style={{ height: "21rem" }}
                    >
                      <span>━ фаза роста ресницы и ее строение</span>
                      <span>━ точный анализ клиента</span>
                      <span>━ разбор валиков и брендов | порядок подбора</span>
                      <span>━ разбор составов и брендов</span>
                      <span>━ зоны нанесения состава</span>
                      <span>━ секреты идеальных и ярких изгибов</span>
                      <span>━ компенсация ресниц</span>
                      <span>━ окрашивание ресниц</span>
                      <span>━ уходовая процедура для ресниц</span>
                      <span>━ очищение и сушка ресниц</span>
                      <span>━ исправления в ламинировании</span>
                      <span>━ фото и видео на макросъемку</span>
                      <span>━ дезинфекция</span>
                    </div>
                    <div className="course-price">
                      <span className="price">💸 20 000₽</span>
                    </div>
                  </div>
                  <Flex gap="3" justify="center">
                    <Dialog.Close>
                      <Button variant="soft" color="gray">
                        Закрыть
                      </Button>
                    </Dialog.Close>
                  </Flex>
                </Dialog.Content>
              </Dialog.Root>
            </div>
          </div>
          <div className="courses-results">
            <img src={p1} alt="" />
            <img src={p2} alt="" />
            <img src={p3} alt="" />
            <img src={p4} alt="" />
            <img src={p5_new} alt="" />
            <img src={p6_new} alt="" />
            <img src={p7} alt="" />
            <img src={p8} alt="" />
            <img src={p9} alt="" />
            <img src={p10_new} alt="" />
            <img src={p11_new} alt="" />
            <img src={p12} alt="" />
          </div>
        </div>
      </section>
      <section>
        <div className="contacts">
          <div className="contact-card" ref={targetRef}>
            <h2 className="contact-card__title">Контакты</h2>
            <p className="contact-card__description">
              Записаться на процедуру и задать любой вопрос вы можете в любой
              удобной для вас соцсети.
            </p>
            <div className="contact-card__buttons">
              <button className="contact-card__button" onClick={onClickInst}>
                ЗАПРЕЩЕНКА
              </button>
              <button className="contact-card__button" onClick={onClickTg}>
                TELEGRAM
              </button>
              <button className="contact-card__button" onClick={onClickWA}>
                WHATSAPP
              </button>
            </div>
          </div>
          <div className="contact-card__image">
            <img src={contactCircle} alt="Profile" />
          </div>
        </div>
      </section>
    </div>
  );
}
